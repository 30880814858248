import React, { memo } from 'react'
import {
  Box,
  Heading,
  Button,
  Stack,
  CloseButton,
  Link,
  BoxProps,
  Icon,
  Text,
  StackProps,
} from '@thirstycamel/ui'

import { useStore, useActions } from '../../store/hooks'
import StoreHours from '../StoreHours'
import StorePickerHeader from '../StorePickerHeader'
import { screens } from '../StorePicker'

/** TODO: Be more creative when naming components */
const TextAndIcon = ({ text, icon, ...restProps }: StackProps & { text: string; icon: string }) => (
  <Stack spacing={1} isInline align="center" {...restProps}>
    <Icon name={icon} fontSize="xs" />
    <Text fontSize="xs" fontWeight="medium">
      {text}
    </Text>
  </Stack>
)

export interface StorePickerStoreProps extends BoxProps {
  closeButtonRef?: React.RefObject<HTMLElement>
  setScreen: (screen: screens) => void
}

export const StorePickerStore = ({
  closeButtonRef,
  setScreen,
  ...restProps
}: StorePickerStoreProps) => {
  const { selectedStore } = useStore(store => store.store)
  const { hidePopout } = useActions(store => store.store)

  const GOOGLE_MAPS_LINK = `https://www.google.com/maps/dir/?api=1&${
    selectedStore?.placeId ? `destination_place_id=${selectedStore?.placeId}&` : ''
  }destination=${selectedStore?.name}, ${selectedStore?.address} ${selectedStore?.postcode}`

  return (
    <Box {...restProps}>
      <Stack
        isInline
        minHeight={['48px', '64px']}
        justify="space-between"
        align="center"
        spacing={5}
      >
        <StorePickerHeader hideIcon />

        <CloseButton isRound color="pink.500" ref={closeButtonRef} onClick={() => hidePopout()} />
      </Stack>

      <Stack spacing={5} pb={8}>
        {selectedStore && (
          <Stack spacing={5} flexShrink={0}>
            {(selectedStore?.isDeliveryReady ||
              selectedStore?.isPickupReady ||
              selectedStore?.isLoyaltyRewards) && (
              <Stack isInline spacing={3}>
                {selectedStore?.isDeliveryReady && <TextAndIcon text="Delivery" icon="truck" />}
                {selectedStore?.isPickupReady && <TextAndIcon text="Pickup" icon="briefcase" />}
                {selectedStore?.isLoyaltyRewards && (
                  <TextAndIcon text="Loyalty Rewards" icon="check-shield" />
                )}
              </Stack>
            )}

            <Stack spacing={5} maxWidth="15rem">
              {selectedStore.hours && <StoreHours hours={selectedStore.hours} />}

              <Stack spacing={3} pr={[, , 3]} mb={[6, , 0]}>
                <Stack spacing={1}>
                  <Heading>{selectedStore.address}</Heading>
                  <Link href={GOOGLE_MAPS_LINK} isExternal fontSize="sm" color="pink.500">
                    Get Directions
                  </Link>
                </Stack>

                <Link
                  href={`tel:${selectedStore.phoneNumber}`}
                  isExternal
                  fontFamily="heading"
                  fontSize="2xl"
                  color="pink.500"
                >
                  {selectedStore.phoneNumber}
                </Link>

                <Stack spacing={3}>
                  {selectedStore.isTopDrops && (
                    <TextAndIcon text="Top Drops" icon="info-outline" spacing={3} />
                  )}

                  {selectedStore.isCrafty && (
                    <TextAndIcon text="Craft Camels" icon="info-outline" spacing={3} />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}

        <Button variant="outline" variantColor="pink" onClick={() => setScreen(screens.SEARCH)}>
          Change Bottleshop
        </Button>
      </Stack>
    </Box>
  )
}

export default memo(StorePickerStore)
