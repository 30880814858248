import React from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  useFormContext,
  FormInputController,
  FormInputControllerProps,
  splitStyleProps,
  FormHelperText,
  forwardRefAs,
  AsProp,
  FormControlProps,
} from '@thirstycamel/ui'
import dlv from 'dlv'

export interface InputProps extends FormControlProps {
  as?: AsProp
  name: string
  onChangeValue?: (value: any) => void
  label?: string
  isRequired?: boolean
  appearsRequired?: boolean
  helperText?: string
  rules?: FormInputControllerProps['rules']
  hideErrors?: boolean
  error?: any
  type?: string
  isReadOnly?: boolean
  isDisabled?: boolean
  render?: (props: any) => React.ReactElement
  inputProps?: any
}

export const Input = forwardRefAs<InputProps, 'input'>(
  (
    {
      as,
      name,
      onChangeValue,
      label,
      isRequired,
      appearsRequired,
      isDisabled,
      isReadOnly,
      helperText,
      error,
      hideErrors,
      type,
      inputProps,
      ...restProps
    },
    ref,
  ) => {
    const { control, setValue, errors } = useFormContext()

    const handleChangeValue = value => {
      setValue(name, value)

      onChangeValue && onChangeValue(value)
    }

    const [_styleProps, _inputProps] = splitStyleProps(restProps)

    const _error = error?.message || (errors && dlv(errors, name)?.message)

    return (
      <FormControl
        {..._styleProps}
        isInvalid={_error}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
      >
        {label && (
          <FormLabel htmlFor={name} isRequired={appearsRequired}>
            {label}
          </FormLabel>
        )}

        <FormInputController
          as={as}
          control={control}
          name={name}
          onChangeValue={handleChangeValue}
          isInvalid={!!_error}
          ref={ref}
          type={type}
          {..._inputProps}
          {...inputProps}
        />

        {!hideErrors && type !== 'hidden' && (
          <FormErrorMessage name={name} error={error?.message} />
        )}

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  },
)

export default Input
