export const storeSearchPlaceholders = {
  VIC: 'e.g. 3000 or Melbourne',
  NSW: 'e.g. 2000 or Sydney',
  QLD: 'e.g. 4000 or Brisbane',
  TAS: 'e.g. 7000 or Hobart',
  WA: 'e.g. 6000 or Perth',
  NT: 'e.g. 0800 or Darwin',
  SA: 'e.g. 5000 or Adelaide',
}

export const postcodePlaceholders = {
  VIC: 'e.g. 3000',
  NSW: 'e.g. 2000',
  QLD: 'e.g. 4000',
  TAS: 'e.g. 7000',
  WA: 'e.g. 6000',
  NT: 'e.g. 0800',
  SA: 'e.g. 5000',
}

export const cityPlaceholders = {
  VIC: 'e.g. Melbourne',
  NSW: 'e.g. Sydney',
  QLD: 'e.g. Brisbane',
  TAS: 'e.g. Hobart',
  WA: 'e.g. Perth',
  NT: 'e.g. Darwin',
  SA: 'e.g. Adelaide',
}
