import React from 'react'
import { Heading, Stack, Text, Icon, StackProps, Flex, Box, Skeleton } from '@thirstycamel/ui'
import { getStoreOpenCloseStatus } from '@ts/core/src/shared/services/time'
import { useStore } from '../../store/hooks'

export interface StorePickerHeaderProps extends StackProps {
  isTruncated?: boolean
  hideIcon?: boolean
  hideDetails?: boolean
}

export const StorePickerHeader = ({
  isTruncated,
  hideIcon,
  children,
  hideDetails,
  ...restProps
}: StorePickerHeaderProps) => {
  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)

  return (
    <Flex direction="column" justify="center" {...restProps}>
      <Stack isInline spacing={2} align="center">
        <Flex direction="column">
          <Heading
            fontSize={['md', 'md']}
            color="pink.500"
            textTransform="uppercase"
            isTruncated={selectedStore?.name && isTruncated}
          >
            {selectedStore?.name || (
              <>
                <Box as="span" display={['none', , , , 'inline']}>
                  What's your local bottleshop?
                </Box>
                <Box as="span" display={[, , , , 'none']}>
                  Select a store
                </Box>
              </>
            )}
          </Heading>

          {!selectedStore &&
            (location.isFetching ? (
              <Skeleton h="1rem" mt={[0, 1]} />
            ) : location.region ? (
              <Text fontSize="xs" fontWeight="medium" mt={[0, '2px']}>
                We think you're in <b>{location.region}</b>
              </Text>
            ) : null)}
        </Flex>

        {hideIcon ? null : <Icon name="chevron-down" fontSize="xl" color="pink.500" />}
      </Stack>

      {selectedStore && !hideDetails && (
        <Text
          fontSize={['xs', 'xs']}
          fontWeight="bold"
          fontFamily="heading"
          color="blackAlpha.700"
          mt={[,0]}
        >
          Liquor Licence: {selectedStore?.liquorLicenseNumber}
        </Text>
      )}

      {selectedStore && !hideDetails && (
        <Text
          fontSize={['xs', 'xs']}
          fontWeight="bold"
          fontFamily="heading"
          color="blackAlpha.700"
          mt={[, 0]}
        >
          {selectedStore?.hours && getStoreOpenCloseStatus(selectedStore.hours)}
        </Text>
      )}
    </Flex>
  )
}

export default StorePickerHeader
