export const GA_TRACKING_ID_UA = 'UA-19186916-2' // This is the legacy tag
export const GA_TRACKING_ID = 'G-9FEVM185XS' // This is your GA4 Tracking ID

// ripped from: https://hoangtrinhj.com/using-google-analytics-with-next-js#2-update-_documentjs

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (typeof window === 'undefined') return
  //@ts-ignore
  window.gtag?.('config', GA_TRACKING_ID, {
    page_path: url,
    non_interaction: true,
    debug_mode: true
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const eventGA4 = (eventName: string, parameters?: any) => {
  if (typeof window === 'undefined') return
  //@ts-ignore
  // window.gtag?.('event', eventName, { event_action: eventName, ...parameters })
  window.gtag?.('event', eventName, { ...parameters, send_to: GA_TRACKING_ID })
}

export const eventUA = (eventName: string, parameters?: any) => {
  if (typeof window === 'undefined') return
  //@ts-ignore
  window.gtag?.('event', eventName, { event_action: eventName, ...parameters, send_to: GA_TRACKING_ID_UA })
}
