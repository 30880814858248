import React from 'react'
import { Text, Flex, Stack, StackProps } from '@thirstycamel/ui'
import type { StoreEntity } from '@ts/core/src/modules/store/store.entity'

const DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export interface StoreHoursProps extends StackProps {
  hours: StoreEntity['hours']
}

export const StoreHours = ({ hours, ...restProps }: StoreHoursProps) => {
  /* Get the numerical day of the week (0-6) to re-order `openingHours` with (must ensure that
   * array starts with Sunday) */
  const todayDayOfWeek = new Date().getDay()

  /* Order the days so today (e.g. if it were a Tuesday) is the first element, then Wed. so forth
   * are in order after that. */
  const days = DAYS.slice(todayDayOfWeek).concat(DAYS.slice(0, todayDayOfWeek))

  return (
    <Stack {...restProps}>
      {days.map((day, index) => (
        <Flex justify="space-between" key={day}>
          <Text
            fontSize={index === 0 ? ['xl', , 'md'] : ['sm', 'md', 'sm']}
            fontFamily="heading"
            fontWeight={index === 0 ? 'bold' : 'medium'}
            textTransform="capitalize"
            mr={5}
          >
            {index === 0 ? 'TODAY' : index === 1 ? 'Tomorrow' : day}
          </Text>

          <Text
            fontSize={index === 0 ? ['lg', , 'sm', 'sm'] : ['sm', 'md', 'sm']}
            fontFamily="heading"
            fontWeight={index === 0 ? 'bold' : 'medium'}
          >
            {!hours[day] ? 'Closed' : `${hours[day][0]} - ${hours[day][1]}`}
          </Text>
        </Flex>
      ))}
    </Stack>
  )
}

export default StoreHours
