import React, { useState, useEffect, memo } from 'react'
import { BoxProps, usePrevious } from '@thirstycamel/ui'

import { useStore } from '../../store/hooks'
import StorePickerSearch from '../StorePickerSearch'
import StorePickerStore from '../StorePickerStore'

export enum screens {
  STORE,
  SEARCH,
  CHOOSE,
}

export interface StorePickerProps extends BoxProps {
  initialFocusRef?: React.RefObject<HTMLElement>
}

export const StorePicker = ({ initialFocusRef, ...restProps }: StorePickerProps) => {
  const { isPopoutOpen, selectedStore } = useStore(store => store.store)

  const initialScreen = selectedStore ? screens.STORE : screens.SEARCH

  const [screen, setScreen] = useState(initialScreen)
  const prevOpen = usePrevious(isPopoutOpen)

  useEffect(() => {
    setScreen(initialScreen)
  }, [isPopoutOpen])

  useEffect(() => {
    /* If the StorePicker has changed screens to SEARCH, focus the search input.
     * This is because the `Popout` has not changed open states and will not do it automatically. */
    if (isPopoutOpen && prevOpen && screen === screens.SEARCH) {
      initialFocusRef.current?.focus()
    }
  }, [screen])

  if (screen === screens.SEARCH) {
    return <StorePickerSearch {...restProps} searchInputRef={initialFocusRef} />
  }

  return <StorePickerStore {...restProps} closeButtonRef={initialFocusRef} setScreen={setScreen} />
}

export default memo(StorePicker)
