import { AxiosError } from 'axios'
import { isAxiosError } from './typeguards'

export const getNiceErrorMessage = (
  error: AxiosError | Error,
  overrides?: { [key: number]: string },
) => {
  if (!error) return error

  if (isAxiosError(error)) {
    if (overrides && overrides[error.response?.status]) {
      return overrides[error.response.status]
    }

    if (error.response?.data?.error === 'Bad Request') {
      return error.response.data.message || 'Whoops! Something went wrong.'
    }

    return (
      error.response?.data?.message ||
      error.response?.data?.error ||
      'Whoops! Something went wrong.'
    )
  }

  return error.message || error
}
